@import "styles/CommonStyles.scss";
.task-page__dropdown--execution-stage {
    box-shadow: 0px 4px 4px $grey-box-shadow;
    border-radius: 4px;
    width: 126px;
    min-height: 153px;
    @include headingLabel;
    display: flex;
    padding: 20px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    background-color: $white-background;
    margin-right: 26px;
    z-index: 2;
    div:nth-child(1) {
        color: $gray-text;
    }
    div:nth-child(2) {
        color: $yellow-text;
    }
    div:nth-child(3) {
        color: $green-text;
    }
}
