@import "styles/CommonStyles.scss";
.common-components__input-with-label-wrapper {
    width: 100%;
    .common-components__input-with-label {
        @include headingLabel;
        color: $gray-text;
        display: flex;
        justify-content: space-between;
    }
    .common-components__input-with-label-input {
        border: 1px solid $border-white;
        border-radius: 6px;
        width: 100%;
        height: 48px;
        margin-top: 10px;
        padding: 12px;
        @include headingLabel;
        color: $black-text;
    }
    .common-components__input-with-label-input::placeholder {
        @include headingLabel;
        color: $gray-text;
    }
    .common-components__input-with-label-show-calendar-icon {
        background-image: url("../../../../public/CalendarIcon.svg");
        background-position: 20px 50%;
        background-repeat: no-repeat;
    }
    .common-components__input-with-label-show-calendar-icon::placeholder {
        @include headingLabel;
        color: $gray-text;
        padding: 12px 12px 12px 46px;
    }
}
