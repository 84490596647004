@import "styles/CommonStyles.scss";
.common-components__filtration-position-wrapper {
    display: flex;
    height: 52px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .common-components__filtration-position-title {
        @include titleLabel;
        color: $black-text;
    }

}
