@import "styles/CommonStyles.scss";
.project-team-page__role-dropdown-content {
    background: $white-background;
    border: 1px solid $border-white;
    box-sizing: border-box;
    box-shadow: 0px -4px 20px $grey-box-shadow;
    border-radius: 8px;
    width: 100%;
    z-index: 2;
    .project-team-page__role-dropdown-item--padding {
        padding: 0 16px;
        .project-team-page__role-dropdown-item-text {
            @include headingLabel;
            color: $black-text;
            height: 50px;
            display: flex;
            align-items: center;
        }
    }
}
