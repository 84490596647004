@import "styles/CommonStyles.scss";
.description-overview-component__label {
    display: flex;
    align-items: center;
    @include headingLabel;
    color: $gray-text;
}
.description-overview-component__content {
    width: 100%;
    @include headingLabel;
    color: $black-text;
}
