@import "styles/CommonStyles.scss";
@mixin commonButtonStyle {
    height: 48px;
    width: 100%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.getting-started-page__introductory-main-text {
    margin-bottom: 64px;
}
.getting-started-page__introductory-main-button--green {
    background: $green-text;
    color: $white-background;
    @include commonButtonStyle;
    margin-bottom: 24px;
}
.getting-started-page__introductory-main-button--gray {
    background: $gray-background;
    color: $gray-text;
    @include commonButtonStyle;
}