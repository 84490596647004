@import "styles/CommonStyles.scss";
.common-components__filtration-header {
    min-height: $header-height;
    display: flex;
    background-color: $gray-background;
    .common-components__filtration-header-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include titleLabel;
        width: 100%;
        padding: $main-layout-indentation;
        color: $gray-text;
        .common-components__filtration-first-part-wrapper {
            display: flex;
            align-items: center;
            span {
                margin-left: 20px;
            }
        }
    }
    .common-components__filtration-header-buttons {
        display: flex;
        div:nth-child(1) {
            margin-right: 8px;
        }
        .common-components__filtration-header-apply-filter-text {
            @include headingLabel;
            color: $white-background;
            border: $green-text;
            width: 68px;
            text-transform: uppercase;
            background: $green-text;
            border-radius: 6px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .common-components__filtration-header-cancel-filter-text {
            @include headingLabel;
            color: $red-accent;
            border: 1px solid $red-accent;
            width: 68px;
            text-transform: uppercase;
            border-radius: 6px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
