@import "styles/CommonStyles.scss";
.no-access__container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
  background: $white-background;
}
.no-access__content {
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.no-access__content--title {
  margin-top: 32px;
  @include titleLabel;
}
.no-access__content--subtitle {
  margin-top: 8px;
  text-align: center;
  color: $gray-text;
  @include headingLabel;
}
