@import "styles/CommonStyles.scss";
.project-page__main {
    @include MainLayout;
    padding: 0px;
    padding-bottom: 78px;
    .project-page__main-content {
        margin-top: 12px;
        .project-page__main-filtration-content {
            padding: 0px $main-layout-indentation;
            .project-page__main-filtration-content-title {
                color: $gray-text;
                @include idTextLabel;
                text-transform: uppercase;
                margin-bottom: 20px;
            }
        }
    }
    .project-page__main-filtration-label {
        display: flex;
        padding: 0 $main-layout-indentation;
        margin: 16px 0;
        align-items: center;
        .project-page__main-filtration-label-text {
            color: $black-text;
            @include headingLabel;
        }
        .project-page__main-filtration-label-count {
            color: $gray-text;
            @include headingLabel;
        }
        div {
            margin-left: 20px;
        }
    }

}
