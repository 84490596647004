@import "styles/CommonStyles.scss";
.common-components__cancel-button {
    background: $gray-background;
    border-radius: 4px;
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include titleLabel;
    color: $gray-text;
}
