@import "styles/CommonStyles.scss";
.common-components__save-button {
    background: $green-text;
    border-radius: 4px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include titleLabel;
    color: $white-background;
    padding: 15px 16px;
}
