@import "styles/CommonStyles.scss";
.common-components__date-badge {
    background: $gray-background;
    border-radius: 6px;
    width: 130px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 14px;
    @include headingLabel;
    color: $gray-text;
}
