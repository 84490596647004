@import "styles/CommonStyles.scss";
.common-components__navigation-route-item-wrapper {
    border-radius: 16px;
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    padding: 0px 14px;
    .common-components__navigation-route-item-location {
        margin-left: 22px;
        @include titleLabel;
    }
}
