@import "styles/CommonStyles.scss";
.deviations-page-main__layout {
    @include MainLayout;
    flex: 1;
    padding-bottom: 100px;
    .deviations-page-main__title {
        color: $gray-text;
        @include idTextLabel;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
}
