@import "styles/CommonStyles.scss";
.profile-page-components__header {
    min-height: $header-height;
    padding: $header-margin-top 16px 0 16px;
    display: flex;
    align-items: center;
    background-color: $gray-background;
    .profile-page-components__header-title {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 11px;
        @include titleLabel;
        color: $gray-text;
    }
}
