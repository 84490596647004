@import "styles/CommonStyles.scss";
.getting-started-page__main-wrapper {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    @include titleLabel;
    height: 100%;
    .getting-started-page__footer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
