@import "styles/CommonStyles.scss";
.common-components__no-item-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin-bottom: 72px;
    .common-components__no-item-title {
        color: $black-text;
        @include titleLabel;
        margin-top: 20px;
        margin-bottom: 13px;
    }
    .common-components__no-item-title--secondary {
        color: $gray-text;
        @include titleLabel;
        text-align: center;
    }
}
