@import "styles/CommonStyles.scss";
.deviation-overview-page__wrapper {
  @include MainLayout;
  flex: 1;
  padding: 0px;
  .deviation-overview-page-main__header {
    margin: 12px;
    padding: 12px;
    border: 1px solid $border-white;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px $grey-box-shadow;
    border-radius: 8px;
    .deviation-overview-page-main__title-wrapper {
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      .deviation-overview-page-main__header-title {
        @include titleLabel;
        color: $black-text;
      }
    }
    .deviation-overview-page__reported-by {
      margin-top: 16px;
      margin-bottom: 12px;
      span:nth-child(1) {
        display: block;
        margin-bottom: 4px;
        @include headingLabel;
        color: $gray-text;
      }
      span:nth-child(2) {
        @include titleLabel;
        color: $black-text;
      }
    }
    .deviation-overview-page__source-wrapper {
      margin-bottom: 12px;
      .deviation-overview-page__source {
        div:nth-child(1) {
          @include idTextLabel;
          color: $gray-text;
        }
        div:nth-child(2) {
          @include titleLabel;
          color: $black-text;
          display: flex;
          align-items: center;
        }
      }
    }
  }
  .deviation-overview-page-main {
    padding: 0 16px;
  }
  .deviation-overview-page-main__assignees {
    width: fit-content;
  }
  .deviation-overview-page-main__loading-text {
    @include headingLabel;
    color: $gray-text;
  }
}
