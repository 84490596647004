@import "styles/CommonStyles.scss";
.user-container-content__item-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  .user-container-content__item {
    width: calc(100% - 32px);
    padding: 8px 12px;
    height: 48px;
    display: flex;
    align-items: center;
    box-shadow: 0px 1px 0px $grey-box-shadow;
    margin-bottom: 2px;
    border-radius: 6px;
    background-color: $white-background;
    div:nth-child(2) {
      margin-left: 14px;
      @include headingLabel;
    }
  }
}
