@import "styles/CommonStyles.scss";
.project-page__tasks-wrapper {
    height: 54px;
    padding: 0 19px 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .project-page__tasks-wrapper-info {
        display: flex;
        align-items: center;
        flex: 1;
        height: 100%;
        .project-page__tasks-category-name {
            margin-left: 8px;
            @include headingLabel;
            color: $black-text;
        }
        .project-page__tasks-count-text {
            @include headingLabel;
            color: $gray-text;
        }
        .project-page__tasks-arrow--bottom {
            margin-left: 20px;
        }
    }
    .project-page__tasks-arrow-wrapper--bottom {
        width: 63px;
        display: flex;
        justify-content: space-between;
        @include headingLabel;
        color: $gray-text;
    }
}
