@import "styles/CommonStyles.scss";
.new-organization-page__main-layout {
    padding: 16px;
    background: $white-background;
    box-shadow: 0px -4px 20px $grey-box-shadow;
    border-radius: 16px 16px 0px 0px;
    flex: 1;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
