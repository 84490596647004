@import "styles/CommonStyles.scss";
.new-task-page-main__layout {
  background-color: $white-background;
  border-radius: 16px 16px 0px 0px;
  padding-top: 24px;
  .new-task-page-main__top-part {
    padding: 0 $main-layout-indentation;
    flex: 1;
  }
  .new-task-page-main__input-wrapper-input {
    border: 1px solid $border-white;
    border-radius: 6px;
    width: 100%;
    height: 48px;
    margin-top: 10px;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    @include headingLabel;
    color: $black-text;
  }
  .new-task-page-main__show-calendar-icon {
    background-image: url("../../../../../../public/CalendarIcon.svg");
    background-position: 20px 50%;
    background-repeat: no-repeat;
  }
  .new-task-page-main__main-team-members {
    padding: 0 $main-layout-indentation;
    flex: 1;
  }
  .mainBottomPart {
    padding: 0 $main-layout-indentation;
    flex: 1;
  }

  .new-task-page-main__priority-and-checklists-dropdown {
    margin-bottom: 24px;
  }
  .new-task-page-main__main-team-members-dropdown {
    margin-bottom: 24px;
    margin-top: 24px;
  }
  .new-task-page-main__project-files-wrapper {
    padding: 0 16px;
  }
}
