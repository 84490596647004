@import "styles/CommonStyles.scss";
.new-project-page__footer {
    background: $white-background;
    box-shadow: 0px -4px 10px rgba(229, 229, 229, 0.3);
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 74px;
    padding: 13px 25px;
    .new-project-page__cancel-button-wrapper {
        height: 100%;
        width: 83px;
        margin-right: 25px;
    }
    .new-project-page__save-button-wrapper {
        height: 100%;
    }
}
