@import "styles/CommonStyles.scss";
.project-page-information-header__main-heading {
  margin: 12px 0;
  padding: 0 $main-layout-indentation;
  height: 72px;
  display: flex;
  .project-page-information-header__main-info-container {
    display: flex;
    flex: 1;
    margin-right: 16px;
    flex-direction: column;
    justify-content: space-evenly;
    .project-page-information-header__main-project-info-container {
      display: flex;
      align-items: center;
      @include headingLabel;
      span {
        max-width: 70%;
        @include wrapLongText;
      }
    }
    .project-page-information-header__main-organization-info {
      @include idTextLabel;
      color: $gray-text;
    }
  }
  .project-page-information-header__main-project-dates {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .project-page-information-header__main-project-dates-wrapper {
      height: 48px;
      margin-left: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include idTextLabel;
      color: $gray-text;
    }
  }
}
