@import "styles/CommonStyles.scss";
.common-components__filtration-bar-wrapper {
    min-height: 37px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .common-components__filtration-bar-inputs {
        display: flex;
        align-items: center;
        width: 100%;
        .common-components__filtration-bar-search-text {
            @include searchText;
            color: $gray-text;
            margin-left: 10px;
            margin-right: 10px;
            border: none;
            height: 32px;
            flex: 1;
            border: 1px solid $transparent-color;
        }
        .common-components__filtration-bar-search-text:focus {
            border: 1px solid $green-text;
            border-radius: 6px;
        }
    }
}
