@import "styles/CommonStyles.scss";
.project-team-page__role-section {
    height: 54px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .project-team-page__role-name {
        color: $black-text;
        @include headingLabel;
    }
    .project-team-page__change-role-button {
        display: flex;
        .project-team-page__change-role-button-title {
            color: $gray-text;
            @include headingLabel;
            display: block;
        }
    }
}