@import "styles/CommonStyles.scss";
.money-value-badge__wrapper {
    @include idTextLabel;
    height: 32px;
    padding: 8.5px 12px;
    border-radius: 6px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    img {
        margin-right: 12px;
    }
}
