@import "styles/CommonStyles.scss";
.tasks-filter-page__layout {
    background-color: $gray-background;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    .tasks-filter-page__main-layout {
        padding: $main-layout-indentation $main-layout-indentation 0 $main-layout-indentation;
        background-color: $white-background;
        border-radius: 16px 16px 0px 0px;
        flex: 1;
    }
}
