@import "styles/CommonStyles.scss";
.common-components__select-button {
    display: flex;
    justify-content: space-between;
    width: 85px;
    height: 20px;
    span {
        @include headingLabel;
        color: $light-gray-text;
        margin-right: 26px;
    }
}
