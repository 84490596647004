@import "styles/CommonStyles.scss";
.task-users-page__layout {
    width: 100%;
    height: 100%;
    background-color: $gray-background;
    display: flex;
    flex-direction: column;
}
.task-users-page__main-layout {
    background-color: $white-background;
    flex: 1;
    box-shadow: 0px -4px 20px $grey-box-shadow;
    border-radius: 16px 16px 0px 0px;
    padding: 24px 16px 16px 16px;
}
