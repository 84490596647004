@import "styles/CommonStyles.scss";
.forms-component__main-checklists-part {
    margin-bottom: 72px;
    .forms-component__main-checklists-part-text {
        margin-bottom: 8px;
        display: block;
        @include headingLabel;
        color: $gray-text;
    }

    .forms-component__main-add-button {
        background-color: $light-green;
        @include idTextLabel;
        color: $green-text;
        width: 100%;
        height: 48px;
        padding: 8px 16px;
        border-radius: 6px;
        margin-top: 24px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
        img {
            margin-right: 16px;
        }
    }
}
