@import "styles/CommonStyles.scss";
.time-entries-page__main-layout {
    @include GrayLayout;
    .time-entries-page__main-no-time-entries-container {
        flex: 1;
        background: $white-background;
        box-shadow: 0px -4px 20px $grey-box-shadow;
        border-radius: 16px 16px 0px 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .time-entries-page__main-time-entries-icon {
            height: 100px;
            display: flex;
            justify-content: center;
        }
        .time-entries-page__main-time-entries-icon-text {
            @include titleLabel;
            color: $gray-text;
        }
        .time-entries-page__main-time-entries-plus-button--green {
            height: 48px;
            margin: 51px 0;
            display: flex;
            justify-content: center;
        }
    }
}
