@import "styles/CommonStyles.scss";
.user-filter-item__layout {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .user-filter-item__layout-left {
        display: flex;
        align-items: center;
        width: 85%;
        .user-filter-item__layout-left-image {
            margin-right: 14px;
            height: 32px;
        }
        .user-filter-item__layout-left-task-name {
            @include wrapLongText;
        }
    }
}
