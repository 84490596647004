@import "styles/CommonStyles.scss";
.web-page__wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    .web-page__content-wrapper {
        height: 500px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        img {
            height: 150px;
            width: auto;
            margin-bottom: 16px;
        }
        .web-page__redirect-button {
            background-color: $green-text;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 48px;
            border-radius: 6px;
            color: $white-background;
        }
        .web-page__text {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 130px;
            text-align: center;
            @include titleLabel;
            color: $gray-text;
            div:nth-child(1) {
                margin-bottom: 32px;
            }
        }
    }
}
