@import "styles/CommonStyles.scss";
.new-task-page-main__dates-input-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    .new-task-page-main__input-wrapper {
        width: 100%;
    }
}
.new-task-page-main__input-wrapper-label {
    @include headingLabel;
    color: $gray-text;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}
.new-task-page-main__input-wrapper-input {
    border: 1px solid $border-white;
    border-radius: 6px;
    width: 100%;
    height: 48px;
    margin-top: 10px;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    @include headingLabel;
    color: $black-text;
}
.new-task-page-main__show-calendar-icon {
    background-image: url("../../../public/CalendarIcon.svg");
    background-position: 20px 50%;
    background-repeat: no-repeat;
}