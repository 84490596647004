@import "styles/CommonStyles.scss";
.time-entry-status__wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    .time-entry-status__status-label {
        @include headingLabel;
        margin-left: 16px;
    }
}
