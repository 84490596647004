@import "styles/CommonStyles.scss";
.checklists-answers-page__main-layout {
    @include GrayLayout;
    display: flex;
    .checklists-answers-page-main__layout {
        flex: 1;
        background: $white-background;
        box-shadow: 0px -4px 20px $grey-box-shadow;
        border-radius: 16px 16px 0px 0px;
        display: flex;
        flex-direction: column;
    }
}
