@import "styles/CommonStyles.scss";
.add-time-entry-switch {
    display: flex;
    align-items: center;
    height: 40px;
    .add-time-entry-switch__text {
        margin-left: 16px;
        @include headingLabel;
        color: $gray-text; 
    }
}
