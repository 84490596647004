@import "styles/CommonStyles.scss";
.common-components__gray-modal-overlay {
    position: absolute;
    outline: none;
    background: rgba(191, 191, 191, 0.4);
    min-width: 100%;
    min-height: 100%;
    padding: 45px 32px;
    display: flex;
    flex-direction: column;
}
.common-components__gray-shadow-modal-overlay {
    position: fixed;
    display: flex;
    inset: 0px;
    flex-direction: column;
    align-items: flex-end;
    z-index: 4;
    justify-content: flex-start;
    overflow-y: auto;
}
