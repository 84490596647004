@import "styles/CommonStyles.scss";
.common-components__organizations-modal-organizations-dropdown {
    border: 1px solid $border-white;
    box-shadow: 0px -4px 20px $grey-box-shadow;
    border-radius: 8px;
    width: 95%;
    margin-top: 4px;
    padding: 4px 16px;
    background: $white-background;
    z-index: 2;
    margin-right: 5%;
    .common-components__organizations-modal-organizations-item {
        height: 56px;
        display: flex;
        align-items: center;
        span {
            @include titleLabel;
            color: $gray-text;
            margin-left: 12px;
            @include wrapLongText;
        }
    }
}
