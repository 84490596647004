@import "styles/CommonStyles.scss";
.project-filters-page__main {
    padding: 0 $main-layout-indentation $main-layout-indentation $main-layout-indentation;
    background-color: $white-background;
    border-radius: 16px 16px 0px 0px;
    flex: 1;
    .project-filters-page__reset-filters {
        height: 56px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @include headingLabel;
        color: $red-accent;
    }
}
