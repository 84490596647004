@import "styles/CommonStyles.scss";
.getting-started-page__return-button {
    height: 48px;
    width: 48px;
    background: $gray-background;
    position: absolute;
    left: 16px;
    border-radius: 99px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
