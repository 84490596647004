@import "styles/CommonStyles.scss";
.project-team-page-main__layout {
    @include MainLayout;
    flex: 1;
    .project-team-page-main__title {
        color: $gray-text;
        @include idTextLabel;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    .project-team-page-main__members {
        margin-top: 12px;
    }
}
