@import "styles/CommonStyles.scss";
.time-entries-page-main__layout {
    @include MainLayout;
    .time-entries-page__main-title {
        color: $gray-text;
        @include idTextLabel;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
}
.organizationsModalOverlay {
    position: fixed;
    display: flex;
    inset: 0px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 7;
    overflow-y: auto;
    background: $modal-overlay-background;
}
.organizationsModal {
    position: absolute;
    outline: none;
    background: $white-background;
    box-shadow: 0px 8px 40px $gray-background;
    border-radius: 16px;
    width: 90%;
    min-height: 35%;
    padding:  32px 16px 16px;
    display: flex;
    flex-direction: column;
}
