@import "styles/CommonStyles.scss";
.checklist-page__main-layout {
    @include MainLayout;
    padding-bottom: 100px;
    .checklist-page__page-title {
        @include idTextLabel;
        color: $gray-text;
        margin-bottom: 12px;
        display: block;
    }
}
