@import "styles/CommonStyles.scss";
.create-deviation-page__main {
    background-color: $white-background;
    border-radius: 16px 16px 0px 0px;
    flex: 1;
    .create-deviation-page__upper-part {
        padding: 16px;
    }
    .create-deviation-page__lower-part {
        padding: 16px;
    }
    .create-deviation-page__files-label {
        @include headingLabel;
        color: $gray-text;
        padding: 0 16px 0 16px;
    }
    .create-deviation-page__project-files-wrapper {
        padding: 0 16px;
    }
}
