@import 'styles/CommonStyles.scss';
@mixin tasksLabelsStyle {
  height: 32px;
  width: 32px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  span {
    @include idTextLabel;
  }
}
.projects-page-components__projects-main-overview-bar {
  height: 48px;
  width: 100%;
  background: $white-background;
  border: 1px solid $border-light-gray;
  box-shadow: 0px 1px 0px $grey-box-shadow;
  border-radius: 6px;
  display: flex;
  margin-bottom: 2px;
  align-items: center;
  justify-content: space-between;
  padding: 0px 8px 0px 16px;
  .projects-page-components__projects-main-overview-bar-left-info-part {
    display: flex;
    align-items: center;
    width: 50%;
    span {
      @include headingLabel;
      color: $black-text;
      @include wrapLongText;
    }
  }
  .projects-page-components__projects-main-overview-bar-right-info-part {
    display: flex;
    .projects-page-components__projects-main-overview-bar-task-background--blue {
      @include tasksLabelsStyle;
      background-color: $gantt-light-blue;
    }
    .projects-page-components__projects-main-overview-bar-task-background--yellow {
      @include tasksLabelsStyle;
      margin: 0 2px;
      background-color: $gantt-light-orange;
    }
    .projects-page-components__projects-main-overview-bar-task-background--green {
      @include tasksLabelsStyle;
      background-color: $gantt-light-green;
    }
  }
}
