@import "styles/CommonStyles.scss";
@mixin commonDivStyle {
    height: 54px;
    border-bottom: 1px solid $border-white;
    display: flex;
    align-items: center;
    @include headingLabel;
    color: $black-text;
}
.profile-page-components__main {
    border-radius: 16px 16px 0px 0px;
    flex: 1 1;
    padding: 16px;
    display: flex;
    flex-direction: column;
    .profile-page-components__main-heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 234px;
        .profile-page-components__main-heading-userCredentials {
            @include titleLabel;
            margin-top: 13px;
            margin-bottom: 4px;
        }
        .profile-page-components__main-heading-text {
            @include searchText;
            color: $gray-text;
        }
    }
    .profile-page-components__user-details-section {
        div:first-child {
            @include commonDivStyle;
            border-top: 1px solid $border-white;
        }
        div {
            @include commonDivStyle;
        }
    }
}
