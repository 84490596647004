@import "styles/CommonStyles.scss";
.project-page-overview-dropdown__tasks-container {
    padding-bottom: 12px;
    .project-page-overview-dropdown__tasks-wrapper {
        padding: 0 16px;
        height: 54px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .project-page-overview-dropdown__tasks-wrapper-info {
            display: flex;
            align-items: center;
            flex: 1;
            height: 100%;
            span {
                @include headingLabel;
                color: $black-text;
            }
            .project-page-overview-dropdown__tasks-category-name {
                margin-left: 8px;
                @include headingLabel;
                color: $black-text;
            }
            .project-page-overview-dropdown__tasks-count-text {
                @include headingLabel;
                color: $gray-text;
            }
            .project-page-overview-dropdown__tasks-arrow--bottom {
                margin-left: 20px;
            }
        }
        .project-page-overview-dropdown__tasks-arrow-wrapper--bottom {
            width: 63px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include headingLabel;
            color: $gray-text;
        }
    }
}
.project-page-overview-dropdown__separation-line-wrapper {
    margin: 0 16px;
}
