@import "styles/CommonStyles.scss";
.documents-page__main-layout {
    @include MainLayout;
    padding-bottom: 100px;
    .documents-page__page-title {
        @include idTextLabel;
        color: $gray-text;
        margin-bottom: 12px;
        display: block;
    }
}
