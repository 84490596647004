@import "styles/CommonStyles.scss";
.add-time-entry__date-container-content {
    height: 50%;
    display: flex;
    align-items: center;
    .add-time-entry__date-container-date-content {
        @include datesLabel;
        color: $black-text;
    }
    .add-time-entry__date-container-date-input {
        border: none;
        width: 100%;
        padding: 0;
        @include datesLabel;
        color: $black-text;
    }
    .add-time-entry__date-container-date-input::placeholder {
        color: $gray-text;
    }
}
