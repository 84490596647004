@import "styles/CommonStyles.scss";
@import "styles/DatePickerStyles.scss";
@import "@fontsource/roboto";
html,
body {
  position: relative;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: "Roboto";
  touch-action: pan-x pan-y;
}

#root {
  height: 100%;
  width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
textarea:focus,
input:focus {
  outline: none;
}

