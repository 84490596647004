@import "styles/CommonStyles.scss";
.common-components__task-info-container-wrapper {
    width: calc(100% - 2 * $main-layout-indentation);
    padding: 0 12px 0 4px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 1px 0px $grey-box-shadow;
    margin-bottom: 2px;
    border-radius: 6px;
    background-color: $white-background;
    .common-components__task-info-container-name-and-id {
        max-width: 65%;
        display: flex;
        align-items: center;
        height: 100%;
        .common-components__task-info-container-title {
            margin-left: 12px;
            margin-right: 12px;
            @include wrapLongText;
            @include headingLabel;
        }
        .common-components__task-info-container-image--custom-size {
            min-width: 4px;
            height: 40px;
        }
        .common-components__task-info-container-task-id-wrapper {
            @include idTextLabel;
            background-color: $gray-background;
            color: $gray-text;
            border-radius: 6px;
            height: 24px;
            padding: 0 8px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .common-components__task-info-container-start-and-end-dates {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        height: 100%;
        justify-content: space-evenly;
        .common-components__task-info-container-start-date {
            @include smallLabel;
            color: $gray-text;
        }
        .common-components__task-info-container-end-date-wrapper {
            display: flex;
            align-items: center;
            .common-components__task-info-container-end-date {
                @include idTextLabel;
                color: $gray-text;
                margin-left: 8px;
            }
        }
    }
}
