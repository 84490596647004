@import "styles/CommonStyles.scss";
.common-components__footer {
    height: 74px;
    width: 100%;
    position: fixed;
    bottom: 0px;
    display: flex;
    align-items: flex-end;
    z-index: 5;
    .common-components__footer-home {
        background-color: $white-background;
        height: 78px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: relative;
        flex-grow: 100;
        .common-components__footer-home--underline {
            position: absolute;
            top: 45px;
        }
    }
    .common-components__footer-center-button-wrapper {
        width: 78px;
        height: 24px;
        background-color: $white-background;
        display: flex;
        position: relative;
        .common-components__footer-center-button {
            position: absolute;
            width: 100%;
            display: flex;
            top: -70px;
            justify-content: space-around;
            z-index: 2;
        }
        .common-components__footer-button--left-indent {
            position: relative;
            height: 72px;
            width: 100%;
            top: -54px;
            background-color: $white-background;
            mask-image: radial-gradient(circle 38px at 50% 10%, transparent 0, transparent 38px, $pure-black 21px);
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: initial;
            background-clip: initial;
            -webkit-mask-image: radial-gradient(
                circle 38px at 50% 10%,
                transparent 0,
                transparent 38px,
                $pure-black 21px
            );
        }
    }

    .common-components__footer-time-entries {
        background-color: $white-background;
        flex-grow: 100;
        height: 78px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .common-components__footer-home--underline {
            position: absolute;
            top: 41px;
        }
    }
}
