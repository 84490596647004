@import "styles/CommonStyles.scss";
.project-team-assign-task__layout {
    height: 100%;
    display: flex;
    flex-direction: column;
    .project-team-assign-task__main-layout {
        @include MainLayout;
        flex: 1;
    }
}
