@mixin titleLabel {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
}
@mixin titleText {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
}
@mixin datesLabel {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    text-transform: uppercase;
}
@mixin headingLabel {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
}
@mixin searchText {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.1px;
}
@mixin idTextLabel {
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
}
@mixin descriptionText {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
}
@mixin smallLabel {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
}
