@import "styles/CommonStyles.scss";
.getting-started-page__log-out-button {
    background: $gray-background;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 8px;
    margin-bottom: 10px;
}
.getting-started-page__log-out-button-text{
    @include headingLabel;
    color: $gray-text;
}