@import "styles/CommonStyles.scss";
.common-components__filtration-menu-button-wrapper {
    width: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
        color: $green-text;
        @include headingLabel;
    }
}
