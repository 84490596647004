@import "styles/CommonStyles.scss";
.common-components__calendar-with-label-wrapper {
    width: 100%;
    .common-components__calendar-with-label {
        @include headingLabel;
        color: $gray-text;
        display: flex;
        justify-content: space-between;
    }
    .common-components__calendar-with-label-input {
        border: 1px solid $border-white;
        border-radius: 6px;
        width: 100%;
        height: 48px;
        margin-top: 10px;
        padding: 12px;
        @include headingLabel;
        color: $black-text;
        display: flex;
        align-items: center;
        .common-components__calendar-with-label-image {
            margin-right: 12px;
        }
    }
    .common-components__calendar-with-label-input::placeholder {
        @include headingLabel;
        color: $gray-text;
    }
}
