@import "styles/CommonStyles.scss";
.common-components__organizations-modal-overlay {
  position: fixed;
  display: flex;
  inset: 0px;
  flex-direction: column;
  align-items: flex-end;
  z-index: 7;
  justify-content: flex-start;
  overflow-y: auto;
}
.common-components__organizations-modal {
  position: absolute;
  outline: none;
  background: $white-background;
  box-shadow: 0px 8px 40px $gray-background;
  border-radius: 16px 0px 0px 16px;
  width: 80%;
  min-height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
}
.common-components__organizations-modal-main {
  flex: 1;
  .common-components__organizations-modal-heading {
    display: flex;
    justify-content: space-between;
    width: 90%;
    .common-components__close-modal-icon {
      height: 48px;
      display: flex;
      align-items: center;
    }
  }
  .common-components__organizations-modal-menu-composition {
    margin-top: 10px;
    z-index: 1;
  }
}
.common-components__organizations-modal-footer {
  margin-top: 24px;
  .common-components__organizations-modal-help-route {
    @include titleLabel;
    color: $gray-text;
    height: 56px;
    display: flex;
    align-items: center;
  }
  .common-components__organizations-modal-sign-out {
    @include titleLabel;
    color: $gray-text;
    height: 56px;
    display: flex;
    align-items: center;
  }
  .common-components__organizations-modal-my-profile-route {
    @include titleLabel;
    color: $gray-text;
    height: 56px;
    display: flex;
    align-items: center;
    div {
      margin-right: 12px;
    }
  }
}
