@import "styles/CommonStyles.scss";
.common-components__team-members-multi-select-label {
    @include headingLabel;
    color: $gray-text;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.common-components__team-members-multi-selected-option--wrapper {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
}
.common-components__team-members-multi-selected-option--single {
    display: flex;
    align-items: center;
    .common-components__team-members-multi-selected-option--single-label {
        margin-left: 12px;
        @include headingLabel;
    }
}