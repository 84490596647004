@import "styles/CommonStyles.scss";
.common-components__footer-add-button-dropdown-button {
  position: absolute;
  width: 186px;
  background: $white-background;
  border-radius: 12px;
  bottom: 65px;
  display: flex;
  flex-direction: column;
  padding: 4px;
  justify-content: space-between;
  z-index: 2;
  .common-components__footer-add-button-dropdown-item-wrapper {
    display: flex;
    align-items: center;
    height: 40px;
    width: 100%;
    border-radius: 6px;
  }
}
.common-components__footer-add-button-dropdown-pointer-element {
  width: 0;
  height: 10px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 25px solid $white-background;
  position: absolute;
  bottom: 55px;
  z-index: 1;
}
