@import "styles/CommonStyles.scss";
@mixin commonFontSizes {
    width: calc(100% / 7);
    line-height: 35px;
}
body {
    .react-datepicker {
        border: none;
        width: 100%;
        .react-datepicker__current-month {
            @include idTextLabel;
        }
        .react-datepicker__header {
            border-top-left-radius: 13px;
            border-bottom: none;
            background: $white-background;
        }
        .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
            border-top-right-radius: 13px;
        }
        .react-datepicker__month-container {
            width: 100%;
        }
        .react-datepicker__day-names {
            margin-top: $main-layout-indentation;
            .react-datepicker__day-name,
            .react-datepicker__day,
            .react-datepicker__time-name {
                @include commonFontSizes;
                color: $gray-text;
                @include idTextLabel;
                margin: 0px;
            }
        }
        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
            @include commonFontSizes;
        }
        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
            color: $black-text;
            @include idTextLabel;
            margin: 0px;
        }
        .react-datepicker__month{
            margin: 0;
        }
        .react-datepicker__day--selected,
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--selected,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--selected,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--selected,
        .react-datepicker__year-text--in-selecting-range,
        .react-datepicker__year-text--in-range {
            background: $light-green;
        }
        .react-datepicker__day--outside-month {
            color: $gray-text;
        }
        [aria-disabled="true"] {
            background-color: $gray-background;
        }
    }
}
