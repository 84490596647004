@import "styles/CommonStyles.scss";
.common-components__bordered-description {
    @include headingLabel;
    color: $gray-text;
    display: flex;
    justify-content: space-between;
}
.common-components__bordered-description-textarea {
    padding: 12px;
    width: 100%;
    height: 116px;
    @include headingLabel;
    border: 1px solid $border-white;
    box-sizing: border-box;
    border-radius: 6px;
    color: $black-text;
    margin-top: 10px;
}
.common-components__bordered-description-textarea::placeholder {
    @include headingLabel;
    color: $gray-text;
}
