@import "styles/CommonStyles.scss";
.time-entry-page-main__layout {
    @include MainLayout;
    padding-bottom: 40px;
    .time-entry-page-main__checkbox-item-wrapper {
        margin-top: 25px;
        margin-bottom: 25px;
        flex: 1;
    }
}
