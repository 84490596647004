@import "styles/CommonStyles.scss";
.add-time-entry__date-container-label {
  display: flex;
  align-items: center;
  .add-time-entry__date-container-calendar {
    margin-right: 8px;
    display: flex;
    align-items: center;
  }
  .add-time-entry__date-container-label--small {
    @include headingLabel;
    color: $gray-text;
    text-transform: uppercase;
  }
}
