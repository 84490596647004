@import "styles/CommonStyles.scss";
.time-entry-tasks__task-filter-item {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .time-entry-tasks__task-filter-item-left-info {
        display: flex;
        align-items: center;
        width: 85%;
        .time-entry-tasks__task-filter-item-text {
            @include wrapLongText;
            @include headingLabel;
        }
    }
}