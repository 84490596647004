@import "styles/CommonStyles.scss";
.document-item__wrapper {
  border: 1px solid $border-white;
  margin-top: 8px;
  border-radius: 8px;
  padding: 12px;
  .document-item__title {
    @include headingLabel;
    span {
      @include wrapLongText;
    }
    display: flex;
    align-items: center;
    img {
      margin-right: 8px;
    }
  }
  .document-item__created-on {
    @include headingLabel;
    margin-top: 8px;
    display: flex;
    align-items: center;
  }
  .document-item__actions {
    display: flex;
    align-items: center;
    @include headingLabel;
    .document-item__action-icons {
      flex: 1;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
  }
}
