@import "styles/CommonStyles.scss";
.project-team-page__user-item-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .project-team-page__user-item-first-part {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .project-team-page__member-name {
            margin-left: 14px;
            @include headingLabel;
            color: $black-text;
        }
    }
    .project-team-page__member-role {
        @include headingLabel;
        color: $gray-text;
    }
}
