@import "styles/CommonStyles.scss";
.radio-button--wrapper {
  display: flex;
  align-items: center;
  min-height: 24px;
  margin-bottom: 16px;
  cursor: pointer;
  .radio-button__single-choice-possible-option {
    -moz-appearance: initial;
    align-items: center;
    display: flex;
    margin-right: 35px;
    margin-left: 0px;
    position: relative;
    height: 0px;
    width: 0px;
  }
  .radio-button__single-choice-possible-option::before {
    content: " ";
    display: block;
    min-width: 17px;
    min-height: 17px;
    margin-right: 14px;
    border: 3px solid $green-text;
    background-color: $white-background;
    border-radius: 50%;}

  .radio-button__single-choice-possible-option:checked::after {
    content: "";
    position: absolute;
    width: 13px;
    height: 13px;
    margin-left: 5px;
    border-radius: 100%;
    background-color: $green-text;
  }
  .radio-button__label {
    @include titleLabel;
    color: $gray-text;
  }
}