@mixin GrayLayout {
    background: $gray-background;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
@mixin MainLayout {
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 75%,
        rgba(245, 245, 245, 1) 100%
    );
    box-shadow: 0px -4px 20px $grey-box-shadow;
    border-radius: $main-layout-indentation $main-layout-indentation 0px 0px;
    flex: 1;
    padding: $main-layout-indentation;
    display: flex;
    flex-direction: column;
}
@mixin wrapLongText {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}