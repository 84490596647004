@import "styles/CommonStyles.scss";
.time-entries__tasks-filter-page-layout {
    background-color: $gray-background;
    .time-entries__task-filter-page-main-layout {
        padding: $main-layout-indentation $main-layout-indentation 0 $main-layout-indentation;
        background-color: $white-background;
        border-radius: 16px 16px 0px 0px;
        flex: 1;
    }
}
