@import "styles/CommonStyles.scss";
.common-components__option-wrapper {
  padding: 0px 8px;
}
.common-components__multi-value {
  @include headingLabel;
  color: $black-text;
  text-justify: center;
}
.common-components__value-container {
  height: 3px;
  z-index: -1;
}
