@import "styles/CommonStyles.scss";
.common-components__header-and-custom-end-element {
    min-height: $header-height;
    padding: $header-margin-top 16px 0 16px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background: $gray-background;
    .common-components__header-and-custom-end-element-logo {
        display: flex;
        align-items: center;
        width: 70%;
        height: 40px;
        div {
            height: 40px;
            min-width: 40px;
            margin-right: 10px;
        }
        span {
            @include titleLabel;
            color: $gray-text;
            @include wrapLongText;
        }
        .common-components__header-and-custom-end-element-logo-wrapper {
            display: flex;
            align-items: center;
            height: 100%;
            .common-components__header-and-custom-element-text {
                @include titleLabel;
                color: $gray-text;
            }
        }
    }
}
