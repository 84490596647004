@import "styles/CommonStyles.scss";
.time-entries__approval-modal-content-header {
    @include titleLabel;
    color: $black-text;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.time-entries__approval-modal-content-main {
    max-height: 300px;
    overflow-y: auto;
    width: calc(100% + 16px);
    padding-right: 16px;
}
.time-entries__approval-modal-content-footer {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    .time-entries__approval-modal-content-cancel-button {
        background: $gray-background;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        text-transform: uppercase;
        @include headingLabel;
        color: $gray-text;
        width: 83px;
        height: 48px;
    }
    .time-entries__approval-modal-content-confirm-button {
        background: $light-green;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        text-transform: uppercase;
        @include headingLabel;
        color: $green-text;
        width: 68px;
        height: 48px;
    }
}
