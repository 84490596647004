@import "styles/CommonStyles.scss";
.error-page__layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .error-page__content-wrapper {
        height: 350px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0 16px;
        .error-page__main-icon {
            font-size: 120px;
            line-height: 155px;
        }
        .error-page__title {
            @include titleLabel;
            color: $red-accent;
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 31px;
            text-align: center;
            margin-top: 30px;
        }
        .error-page__subtitle {
            @include titleLabel;
            color: $gray-text;
            margin-top: 10px;
        }
        .error-page__logout-button {
            background: $green-text;
            border-radius: 4px;
            margin-top: 44px;
            @include titleLabel;
            color: $white-background;
            margin-top: 44px;
            height: 48px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
