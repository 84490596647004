@import "styles/CommonStyles.scss";
.common-components__page-title-and-return {
    min-height: $header-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    div {
        @include titleLabel;
        color: $gray-text;
    }
}