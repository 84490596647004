@import "styles/CommonStyles.scss";
#custom-input input[type="checkbox"] {
    visibility: hidden;
}

.common-components__checkbox-control {
    display: block;
    position: relative;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
}

.common-components__checkbox-control input[type="checkbox"] {
    z-index: -1;
    opacity: 0;
}

.common-components__control-indicator {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    border: 3px solid $green-text;
    border-radius: 3px;
}

.common-components__checkbox-control:hover input[type="checkbox"]~.common-components__control-indicator,
.common-components__checkbox-control input[type="checkbox"]:focus~.common-components__control-indicator {
    background: $white-background;
}

.common-components__checkbox-control input[type="checkbox"]:checked~.common-components__control-indicator {
    background: $green-text;
}

.common-components__checkbox-control input[type="checkbox"]:checked:disabled~.common-components__control-indicator {
    background: $green-text;
    opacity: 0.5;
    pointer-events: none;
    cursor: auto;
}

.common-components__checkbox-control:hover input[type="checkbox"]:not([disabled]):checked~.common-components__control-indicator,
.common-components__checkbox-control input[type="checkbox"]:checked:focus~.common-components__control-indicator {
    background: $green-text;
}

.common-components__checkbox-control input[type="checkbox"]:disabled~.common-components__control-indicator {
    pointer-events: none;
}

.common-components__control-indicator:after {
    box-sizing: unset;
    content: "";
    position: absolute;
    display: none;
}

.common-components__checkbox-control input[type="checkbox"]:checked~.common-components__control-indicator:after {
    display: block;
}

.common-components__control-checkbox .common-components__control-indicator:after {
    left: 5px;
    top: 1px;
    width: 3px;
    height: 8px;
    border: solid $white-background;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.common-components__control-checkbox input[type="checkbox"]:disabled~.common-components__control-indicator:after {
    border-color: $white-background;
    pointer-events: none;
    cursor: none;
}

.common-components__control-checkbox .common-components__control-indicator::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 4.001rem;
    height: 4.001rem;
    margin-left: -1.3rem;
    margin-top: -1.3rem;
    border-radius: 3rem;
    opacity: 0.6;
    z-index: 99999;
    transform: scale(0);
}

.common-components__control-checkbox input[type="checkbox"]+.common-components__control-indicator::before {
    animation: s-ripple 250ms ease-out;
}

.common-components__control-checkbox input[type="checkbox"]:checked+.common-components__control-indicator::before {
    animation-name: s-ripple-dup;
}