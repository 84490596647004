@import "styles/CommonStyles.scss";
.common-components__colored-badge {
    @include idTextLabel;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    height: 32px;
    width: fit-content;
}
