@import "styles/CommonStyles.scss";
.checklist-answers-page__common-input--label {
    @include headingLabel;
    color: $gray-text;
    display: flex;
    justify-content: space-between;
}
.checklist-answers-page__common-input--input {
    width: 100%;
    height: 48px;
    border: 1px solid $border-white;
    border-radius: 6px;
    @include headingLabel;
    padding: 12px;
    margin-top: 8px;
}
.checklist-answers-page__common-input--input::placeholder {
    @include headingLabel;
    color: $gray-text;
}
