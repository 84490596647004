@import "styles/CommonStyles.scss";
.list-of-tasks__main-layout {
    background-color: $white-background;
    border-radius: 16px 16px 0px 0px;
    flex: 1;
    margin-top: 16px;
    .list-of-tasks__filter-wrapper {
        margin-top: 24px;
        margin-bottom: 8px;
    }
}
