@import "styles/CommonStyles.scss";
.add-time-entry-page__main {
    @include MainLayout;
    padding-bottom: 40px;
    .add-time-entry-page__checkbox-item-wrapper {
        margin-top: 25px;
        margin-bottom: 25px;
        flex: 1;
    }
}
