@import "styles/CommonStyles.scss";
.task-page__header-wrapper {
    width: 100%;
    height: 72px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $main-layout-indentation;
    .task-page__header-icons--right {
        display: flex;
        height: 20px;
    }
}
