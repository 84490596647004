@import "styles/CommonStyles.scss";
@mixin commonButtonStyle {
    height: 48px;
    width: 100%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    @include titleLabel;
}
.getting-started-page__join-organization-main {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.getting-started-page__join-organization-main-text {
    margin-bottom: 64px;
}
.getting-started-page__join-organization-button--green {
    background: $green-text;
    color: $white-background;
    @include commonButtonStyle;
    margin-bottom: 24px;
    margin-top: 24px;
}
.getting-started-page__join-organization-button--gray {
    background: $gray-background;
    color: $gray-text;
    @include commonButtonStyle;
    border: none;
    text-align: center;
}
.getting-started-page__join-organization-button--gray::placeholder {
    color: $gray-text;
    @include commonButtonStyle;
    text-align: center;
}
