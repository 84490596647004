@import "styles/CommonStyles.scss";
.project-page__tasks-list--gray-background {
    background-color: $gray-background;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
    .common-components__task-info-container-wrapper {
        width: calc(100% - 2 * $main-layout-indentation);
        padding: 0 12px 0 4px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0px 1px 0px $grey-box-shadow;
        margin-bottom: 2px;
        border-radius: 6px;
        background-color: $white-background;
    }
}
