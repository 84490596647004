@import "styles/CommonStyles.scss";
.time-entries-filters-page__main {
    padding: 0 $main-layout-indentation $main-layout-indentation $main-layout-indentation;
    .time-entries-filters-page__main-reset-filters {
        height: 56px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @include headingLabel;
        color: $red-accent;
    }
}
