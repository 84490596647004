@import "styles/CommonStyles.scss";
.add-time-entry__dropdown-wrapper {
    display: flex;
    justify-content: space-between;
    height: 55px;
    align-items: center;
    .add-time-entry__dropdown-title {
        @include headingLabel;
    }
    .add-time-entry__dropdown-item {
        display: flex;
        justify-content: center;
        align-items: center;
        .add-time-entry__dropdown-dot {
            margin-right: 8px;
            margin-bottom: 3px;
        }
        .add-time-entry__dropdown-text--secondary {
            @include headingLabel;
            margin-right: 22px;
        }
    }
}
