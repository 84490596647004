@import "styles/CommonStyles.scss";
@mixin descriptionAndLabelCommonPart {
  margin-top: 28px;
  @include headingLabel;
  color: $gray-text;
}
.task-page__main {
  @include MainLayout;
  flex: 1;
  padding: $main-layout-indentation;
  padding-top: 28px;
  .task-page__main-wrapper {
    display: flex;
    justify-content: space-between;
    .task-page__name-input {
      border: none;
      @include titleLabel;
      color: $black-text;
      width: fit-content;
    }
    .task-page__title-wrapper {
      margin-right: 16px;
      display: flex;
      align-items: center;
      @include titleLabel;
      .task-page__title {
        margin-right: 8px;
      }
    }
    .task-page__calendar-wrapper-first {
      img {
        margin-right: 16px;
      }
      display: flex;
      align-items: center;
      @include headingLabel;
      color: $gray-text;
      margin-bottom: 16px;
    }
    .task-page__calendar-wrapper {
      img {
        margin-right: 16px;
      }
      display: flex;
      align-items: center;
      @include headingLabel;
      color: $gray-text;
    }
    .task-page__calendar-due-date-component-wrapper {
      left: 16px;
      width: 100%;
      position: absolute;
    }
    .task-page__calendar-start-date-component-wrapper {
      left: 16px;
      width: 100%;
      position: absolute;
    }
  }
  .task-page__main-assigned-to-part {
    margin-top: 20px;
    @include headingLabel;
    color: $gray-text;
    .task-page__main-assigned-to--icon {
      margin-top: 16px;
      display: flex;
    }
  }
  .task-page__main-description-part {
    @include descriptionAndLabelCommonPart;
    .task-page__main-description-part-text {
      margin-bottom: 8px;
      display: block;
    }
    span:nth-child(3) {
      margin-top: 12px;
      display: block;
      @include titleText;
      color: $black-text;
    }
    span:nth-child(4) {
      margin-top: 12px;
      display: block;
      @include headingLabel;
      color: $green-text;
      text-decoration: underline;
    }
    .task-page__no-permission-description {
      margin-top: 16px;
    }
  }
  .task-page__main-no-description {
    margin-top: 20px;
    display: block;
    resize: none;
    border: none;
    width: 100%;
    @include headingLabel;
    color: $black-text;
  }
  .task-page__main-no-description::placeholder {
    color: $gray-text;
  }
  .task-page__main-add-button {
    background-color: $light-green;
    @include idTextLabel;
    color: $green-text;
    width: 100%;
    height: 48px;
    padding: 8px 16px;
    border-radius: 6px;
    margin-top: 24px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    img {
      margin-right: 16px;
    }
  }
  .task-page__main-files-part {
    @include descriptionAndLabelCommonPart;
    margin-bottom: 16px;
    .task-page__main-files-part-text {
      margin-bottom: 8px;
      display: block;
    }
  }

  .task-page__main-forms {
    display: flex;
    margin-top: 24px;
  }
  .task-page__loading-text {
    margin: 16px 0;
  }
}
