@import "styles/CommonStyles.scss";
.only-right-button-footer__wrapper {
    width: 100%;
    height: 74px;
    padding: 13px 25px;
    display: flex;
    justify-content: flex-end;
    box-shadow: 0px -4px 10px rgba(229, 229, 229, 0.3);
    .only-right-button-footer__button {
        background-color: $green-text;
        height: 48px;
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include headingLabel;
        text-transform: uppercase;
        color: $white-background;
        border-radius: 6px;
    }
}
