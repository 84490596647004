@import "styles/CommonStyles.scss";
.project-team-page-member-main__layout {
    @include MainLayout;
    padding: 0px;
    flex: 1;
    .project-team-page-member-main__custom-padding {
        padding: 16px 16px 0 16px;
        .project-team-page-member-main__title {
            display: block;
            color: $gray-text;
            @include idTextLabel;
            text-transform: uppercase;
            margin-bottom: 24px;
        }
        .project-team-page-member__user-credentials {
            display: flex;
            align-items: center;
            .project-team-page__member-name {
                margin-left: 14px;
                @include headingLabel;
                color: $black-text;
            }
        }
    }
    .project-team-page__dropdown-max-space {
        max-height: 54px;
    }
    .project-team-page-member-main__assign-task-buttons {
        display: flex;
        margin-bottom: 32px;
        .project-team-page-member-main__create-task-button {
            padding: 8px 16px;
            background: $green-text;
            border-radius: 6px;
            text-transform: uppercase;
            @include idTextLabel;
            color: $white-background;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-right: 16px;
            .project-team-page-member-main__create-task-icon {
                margin-right: 16px;
            }
        }
        .project-team-page-member-main__assign-task-button {
            background: $white-background;
            border: 1px solid $green-text;
            box-sizing: border-box;
            border-radius: 6px;
            text-transform: uppercase;
            @include idTextLabel;
            color: $green-text;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 8px 16px;
        }
    }
    .project-team-page__tasks-lists-wrapper {
        margin-bottom: 96px;
    }
    .project-team-page__no-tasks-container {
        padding: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: 275px;
        .project-team-page__no-tasks-title {
            @include titleLabel;
            color: $black-text;
        }
        .project-team-page__assign-or-create-task--button {
            border: 1px solid $green-text;
            box-sizing: border-box;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            @include headingLabel;
            text-transform: capitalize;
            color: $green-text;
            padding: 12px 16px;
        }
    }
}
