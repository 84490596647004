@import "styles/CommonStyles.scss";
.deviation-item__wrapper {
    background: $white-background;
    border: 1px solid $border-white;
    box-shadow: 0px 1px 0px $grey-box-shadow;
    border-radius: 6px;
    margin-top: 8px;
    .deviation-item__heading {
        padding: 16px 12px 0px 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .deviation-item__title-and-icon {
            display: flex;
            align-items: center;
            .deviation-item__name {
                @include headingLabel;
                color: $black-text;
                margin-left: 8px;
            }
        }
        .deviation-item__deadline {
            @include headingLabel;
            color: $gray-text;
        }
    }
    .deviation-item__main {
        padding: 0px 12px 16px 12px;
        margin-top: 8px;
        .deviation-item__badges {
            display: flex;
        }
        .deviation-item__assignees {
            margin-top: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    .deviation-item__footer {
        padding: 14px 12px 14px 12px;
        display: flex;
        .deviation-item__footer-label {
            @include idTextLabel;
            color: $gray-text;
            padding-right: 16px;
            .deviation-item__footer-cost {
                @include titleLabel;
                color: $red-accent;
                margin-left: 4px;
            }
            .deviation-item__footer-duration {
                @include titleLabel;
                color: $black-text;
                margin-left: 4px;
            }
        }
    }
}
