@import "styles/CommonStyles.scss";
.common-components__responsible-user-avatar-and-credentials {
    display: flex;
    .common-components__responsible-user-credentials {
        div:nth-child(1) {
            @include headingLabel;
            color: $black-text;
            margin-left: 8px;
        }
        div:nth-child(2) {
            @include idTextLabel;
            color: $gray-text;
            margin-left: 8px;
        }
    }
}