@import "styles/CommonStyles.scss";
.projects-page-components__projects-main {
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 75%,
        rgba(245, 245, 245, 1) 100%
    );
    flex: 1;
    box-shadow: 0px -4px 20px $grey-box-shadow;
    border-radius: 16px 16px 0px 0px;
    padding: $main-layout-indentation;
    display: flex;
    flex-direction: column;
    .projects-page-components__projects-main-heading {
        padding: 24px 0 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .projects-page-components__projects-main-heading--wrapper {
            display: flex;
            align-items: center;
        }
        .projects-page-components__projects-main-heading--text {
            margin-left: 14px;
            color: $gray-text;
            @include headingLabel;
        }
        .projects-page-components__projects-main-heading--text-without-margin{
            color: $gray-text;
            @include headingLabel;
        }
        .project-page__new-project-button--small {
            text-transform: uppercase;
            border: 1px solid $green-text;
            box-sizing: border-box;
            border-radius: 6px;
            width: 131px;
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            @include headingLabel;
            color: $green-text;
        }
    }
    .projects-page-components__projects-main-no-filters-result {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        .projects-page-components__projects-main-no-filters-result-text {
            color: $black-text;
            @include titleLabel;
            margin-top: 16px;
            margin-bottom: 12px;
        }
        .projects-page-components__projects-main-info-text {
            @include titleLabel;
            color: $gray-text;
            margin-bottom: 32px;
        }
        .projects-page-components__projects-main-clear-filters-button {
            padding: 12px 16px;
            border: 1px solid $green-text;
            border-radius: 6px;
            text-transform: uppercase;
            @include headingLabel;
            color: $green-text;
        }
    }
}
