@import "styles/CommonStyles.scss";
.common-components__gray-filtration-bar-wrapper {
    background: $gray-background;
    border-radius: 4px;
    display: flex;
    height: 40px;
    align-items: center;
    input {
        @include searchText;
        color: $gray-text;
        height: 100%;
        border: none;
        border: 1px solid $transparent-color;
        background: $gray-background;
        width: 90%;
        padding: 0 16px;
    }
}
