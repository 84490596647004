@import "styles/CommonStyles.scss";
.common-components__form-status-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 14px 0px;
    .common-components__form-status-bar-left {
        display: flex;
        align-items: center;
        img {
            margin-right: 12px;
        }
        span {
            @include headingLabel;
        }
    }
    .common-components__form-status-bar-right {
        margin-right: 12px;
        height: 16px;
    }

}
