@import "styles/CommonStyles.scss";
.time_entries_approval-item {
  background: $white-background;
  box-shadow: 0px 1px 0px $grey-box-shadow;
  border-radius: 6px;
  height: 40px;
  margin-top: 2px;
  display: flex;
  align-items: center;
  @include idTextLabel;
  color: $black-text;
  justify-content: space-between;
  .time_entries_approval-item-task-name {
    @include wrapLongText;
    min-width: 20%;
    max-width: 20%;
    margin-left: 16px;
  }
  .time_entries_approval-item-date {
    min-width: 35%;
    max-width: 35%;
    color: $gray-text;
  }
  .time_entries_badge--empty {
    width: 50px;
  }
}
