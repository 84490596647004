@import "styles/CommonStyles.scss";
.common-components__checklist-item {
    background: linear-gradient(180deg, $white-background 0%, rgba(255, 255, 255, 0) 100%), $white-background;
    border: 1px solid $border-white;
    box-sizing: border-box;
    box-shadow: 0px 1px 0px $grey-box-shadow;
    border-radius: 6px;
    padding: 12px 13px;
    margin-top: 16px;
    @include headingLabel;
    color: $gray-text;
    .common-components__checklist-item-upper-part {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
        .common-components__checklist-item-upper-left-part {
            display: flex;
            align-items: center;
            width: 100%;
            .common-components__checklist-item-upper-left-part-text {
                margin-left: 10px;
                @include wrapLongText;
            }
        }
    }
    .common-components__checklist-item-lower-part {
        height: 32px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .common-components__checklist-item-responsible {
            @include wrapLongText;
        }
        .common-components__checklist-item-lower-part-text {
            color: $black-text;
            width: 40%;
            text-align: end;
            @include wrapLongText;
        }
    }
}
