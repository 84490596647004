@import "styles/CommonStyles.scss";
.not-found-page__layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .not-found-page__content-wrapper {
        height: 350px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0 16px;
        .not-found-page__title {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 120px;
            line-height: 155px;
            color: $red-accent;
            img {
                margin: 0 10px;
            }
        }
        .not-found-page__explanation {
            span {
                display: block;
                text-align: center;
            }
            span:nth-child(1) {
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 31px;
                text-align: center;
                color: $red-accent;
                margin-bottom: 8px;
            }
            .not-found-page__second-explanation-text {
                @include titleLabel;
                color: $gray-text;
            }
        }
        .not-found-page__return-button {
            background: $green-text;
            border-radius: 4px;
            margin-top: 44px;
            @include titleLabel;
            color: $white-background;
            margin-top: 44px;
            height: 48px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
