@import "styles/CommonStyles.scss";
.projects-page-components__projects-header {
    min-height: $header-height;
    .projects-page-components__projects-header-wrapper {
        margin: $header-margin-top $main-layout-indentation 0 $main-layout-indentation;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .projects-page-components__projects-header-title {
            @include titleLabel;
            color: $gray-text;
        }
    }
}
