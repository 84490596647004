@import "styles/CommonStyles.scss";
.add-file-button__main-add-button {
    background-color: $light-green;
    @include idTextLabel;
    color: $green-text;
    width: 100%;
    height: 48px;
    padding: 8px 16px;
    border-radius: 6px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        margin-right: 16px;
    }
}