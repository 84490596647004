@import "styles/CommonStyles.scss";
.common-components__organizations-modal-dropdown-button {
    border: 1px solid $gray-text;
    border-radius: 99px;
    height: 48px;
    width: 85%;
    margin-right: 15%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include wrapLongText;
    div:first-child {
        display: flex;
        align-items: center;
        margin-left: 8px;
        width: 80%;
    }
    .common-components__organizations-modal-organization-name {
        margin-left: 16px;
        @include titleLabel;
        color: $gray-text;
        @include wrapLongText;
    }
    .common-components__organizations-modal-dropdown-arrow {
        margin-right: 20px;
        display: flex;
        align-items: center;
    }
}
