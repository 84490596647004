@import "styles/CommonStyles.scss";
.time-entries-day__layout {
    display: flex;
    justify-content: space-between;
    @include idTextLabel;
    color: $gray-text;
    padding: 11px 12px;
    width: 100%;
    height: 40px;
    background: $gray-background;
    margin-top: 8px;
    .time-entries-day__text--black {
        color: $black-text;
    }
}
