@import "styles/CommonStyles.scss";
.project-overview-page-main__layout {
    flex: 1;
    background: $white-background;
    box-shadow: 0px -4px 20px $grey-box-shadow;
    border-radius: 16px 16px 0px 0px;
    .project-overview-page-main__badges-layout {
        padding: 0 16px;
        div:nth-child(1) {
            margin-right: 8px;
        }
    }
}
