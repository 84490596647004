@import "styles/CommonStyles.scss";
.time-entries-week__layout {
    display: flex;
    justify-content: space-between;
    @include idTextLabel;
    color: $light-gray-text;
    margin-top: 24px;
    margin-bottom: 12px;
    .time-entries-week__text--black {
        color: $black-text;
    }
}
