@import "styles/CommonStyles.scss";
@mixin commonButtonStyle {
    height: 48px;
    width: 100%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.getting-started-page__create-organization-main {
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    .getting-started-page__create-organization-main-text {
        margin-bottom: 56px;
        margin-top: 44px;
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .getting-started-page__create-organization-main-button--green {
        background: $green-text;
        color: $white-background;
        @include commonButtonStyle;
        margin-bottom: 24px;
    }
}
