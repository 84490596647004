@import "styles/CommonStyles.scss";
.checklists-answers-page-main__header {
    margin: 12px;
    padding: 12px;
    border: 1px solid $border-white;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px $grey-box-shadow;
    border-radius: 8px;
    .checklists-answers-page-main__header-info {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        height: 40px;
        .checklists-answers-page-main__header-right-info {
            display: flex;
            align-items: center;
            @include titleLabel;
            color: $black-text;
            width: 100%;
            .checklists-answers-page-main__checklist-name {
                margin-right: 8px;
                @include wrapLongText;
            }
        }
    }
    .checklists-answers-page-main__header-colored-badge {
        margin-bottom: 16px;
    }
    .checklists-answers-page-main__responsible {
        @include idTextLabel;
        color: $gray-text;
        margin-bottom: 8px;
        display: block;
    }
}
.checklists-answers-page-main__checklist-description {
    margin-top: 16px;
}
