@import "styles/CommonStyles.scss";
.common-components__wrapper {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    @include titleLabel;
    .common-components__underline {
        height: 4px;
        border-radius: 8px;
        width: 100%;
    }
}
