@import "styles/CommonStyles.scss";
.time-entries__send-to-approval-button {
    position: fixed;
    bottom: 78px;
    right: 16px;
    left: 16px;
    height: 48px;
    background: linear-gradient(180deg, $light-green 0%, #d4fee8 100%);
    border: 1px solid $green-text;
    box-sizing: border-box;
    box-shadow: 4px 4px 4px $grey-box-shadow;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: $green-text;
    @include titleLabel;
    z-index: 6;
}
