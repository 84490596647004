@import "styles/CommonStyles.scss";
.common-components__from-to-inputs {
    margin-bottom: 8px;
    .common-components__from-to-inputs-total-label {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        color: $gray-text;
        margin-bottom: 10px;
        @include smallLabel;
        .common-components__from-to-inputs-total-label-hours {
            @include headingLabel;
            color: $black-text;
            margin-left: 4px;
        }
    }
}
