@import "styles/CommonStyles.scss";
.common-components__time-entry-description {
    height: 49px;
    display: flex;
    align-items: center;
    @include headingLabel;
}
.common-components__time-entry-description-textarea {
    border: none;
    padding: 0px;
    width: 100%;
    height: 116px;
    @include headingLabel;
}
