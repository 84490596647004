@import "styles/CommonStyles.scss";
.common-components__hours-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .common-components__hours-container-wrapper {
        border: 1px solid $border-white;
        box-sizing: border-box;
        border-radius: 4px;
        height: 54px;
        padding: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
