@import "styles/CommonStyles.scss";
.project-page__new-task-button {
    text-transform: uppercase;
    border: 1px solid $green-text;
    box-sizing: border-box;
    border-radius: 6px;
    width: 121px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include headingLabel;
    color: $green-text;
    margin-top: 45px;
}
