@import "styles/CommonStyles.scss";
.common-components__checkbox-item-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .common-components__checkbox-item-text {
        @include titleLabel;
        color: $gray-text;
        margin-left: 12px;
    }
}
