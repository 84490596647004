@import "styles/CommonStyles.scss";
.common-components__dropdown-position-item-wrapper {
    display: flex;
    align-items: center;
    height:100%;
    padding-left: 16px;
    padding-right: 16px;
    .common-components__dropdown-position-text {
        text-transform: uppercase;
        margin-left: 16px;
        @include idTextLabel;
        color: $green-text;
    }
}
