@import "styles/CommonStyles.scss";
.time-entry__layout {
    padding: 8px 12px;
    display: flex;
    align-items: center;
    height: 76px;
    margin-top: 8px;
    background: $white-background;
    border: 1px solid $grey-box-shadow;
    box-sizing: border-box;
    box-shadow: 0px 1px 0px $grey-box-shadow;
    border-radius: 6px;
    .time-entry__checkbox {
        margin-right: 12px;
    }
    .time-entry__content {
        width: 89%;
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        .time-entry__content-heading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .time-entry__id-badge {
                display: flex;
                align-items: center;
            }
        }
        .time-entry__heading-text {
            @include headingLabel;
            color: $black-text;
        }
        .time-entry__description {
            max-width: 100%;
            @include wrapLongText;
            @include descriptionText;
        }
    }
}
