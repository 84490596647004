@import "styles/CommonStyles.scss";
.edit-deviation-page__main {
  @include MainLayout;
  flex: 1;
  .edit-deviation-page-main__loading-text {
    @include headingLabel;
    color: $gray-text;
    padding: 12px;
  }
}
